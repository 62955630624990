<template>
  <div>
    <Breadcrumb :breadcrumbList="breadcrumbList" />
    <!-- 顶部图片 -->
    <div class="header">SUCCESS STORIES</div>
    <div class="listBox">
      <div
        class="list"
        v-for="(item, index) in listData"
        :key="index"
        @click="successInfo(item.id)"
      >
        <div class="list_title">{{ item.name }}</div>
        <div class="list_content">{{ item.titleDescription }}</div>
        <img src="@/assets/index/more.png" alt="" />
      </div>
    </div>
    <!-- 分页按钮 -->
    <div class="page">
      <div class="page_last">
        <img src="@/assets/index/last2.png" alt="" v-show="page == 1" />
        <img src="@/assets/index/last.png" alt="" v-show="page == 1" />
        <img
          src="@/assets/index/next2.png"
          alt=""
          v-show="page != 1"
          style="transform: rotate(180deg)"
          @click="changePage(1)"
        />
        <img
          src="@/assets/index/next.png"
          alt=""
          v-show="page != 1"
          style="transform: rotate(180deg)"
          @click="last"
        />
      </div>
      <span v-show="page - 3 > 0" @click="changePage(1)">1</span>
      <span v-show="page - 3 > 0">···</span>
      <span v-show="page - 2 > 0" @click="changePage(page - 2)">{{
        page - 2
      }}</span>
      <span v-show="page - 1 > 0" @click="changePage(page - 1)">{{
        page - 1
      }}</span>
      <span style="color: black">{{ page }}</span>
      <span v-show="page + 1 <= pages " @click="changePage(page + 1)">{{
        page + 1
      }}</span>
      <span v-show="page + 2 <= pages" @click="changePage(page + 2)">{{
        page + 2
      }}</span>
      <span v-show="page + 3 < pages">···</span>
      <span v-show="page + 3 <= pages" @click="changePage(pages)">{{
        pages
      }}</span>
      <div class="page_next">
        <img
          src="@/assets/index/last.png"
          alt=""
          v-show="page == pages ||  pages == 0"
          style="transform: rotate(180deg)"
        />
        <img
          src="@/assets/index/last2.png"
          alt=""
          v-show="page == pages ||  pages == 0"
          style="transform: rotate(180deg)"
        />
        <img
          src="@/assets/index/next.png"
          alt=""
          v-show="page != pages  && pages != 0"
          @click="next"
        />
        <img
          src="@/assets/index/next2.png"
          alt=""
          v-show="page != pages  && pages != 0"
          @click="changePage(pages)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/breadcrumb/breadcrumb";
import api from "@/utils/api";
export default {
  mounted() {
    //面包屑导航
    this.breadcrumbList = [
      { path: "/", title: "HOME" },
      { path: "", title: "SUCCESS STORIES" },
    ];
    this.successStoriesList();
  },
  data() {
    return {
      breadcrumbList: [],
      listData: [], //请求的数据
      page: 1, //当前页
      pages: 10, //总页数
    };
  },
  components: {
    Breadcrumb,
  },
  methods: {
    //下一页
    next() {
      this.page += 1;
      this.successStoriesList();
    },
    //上一页
    last() {
      this.page -= 1;
      this.successStoriesList();
    },
    //点击页码换页
    changePage(num) {
      this.page = num;
      this.successStoriesList();
    },
    //成功案例的跳转
    successInfo(id) {
      this.$router.push({
        // name: "successInfo",
        // params: { id },
		path: "/successInfo",
		query: { id },
      });
    },
    //获取成功案例信息
    successStoriesList() {
      api.successStoriesList({page:this.page}).then((res) => {
        let data = res.data;
        if (data.code == 200) {
          this.pages = Math.ceil(data.data.total/10);
          this.listData = data.data.list;
        } else {
          this.$message.error(data.msg);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url("../../assets/index/carousel.png");
  height: 186px;

  font-size: 56px;
  font-family: Arial;
  font-weight: bold;
  color: #ffffff;
  line-height: 186px;
  text-align: center;
}
.listBox {
  padding: 20px 0 30px;
  width: 1156px;
  margin: 0 auto;
  .list {
    height: 93px;
    position: relative;
    .list_title {
      font-size: 14px;
      font-family: Arial;
      font-weight: bold;
      color: #0d53a1;
      padding: 26px 0 10px 12px;
      width: 850px;
      height: 14px;
      overflow: hidden;
    }
    .list_content {
      padding-left: 12px;
      font-size: 12px;
      font-family: Arial;
      font-weight: normal;
      color: #5a5b5e;
      width: 850px;
      height: 14px;
      overflow: hidden;
    }
  }
  .list:nth-of-type(2n-1) {
    background: #f1f2f7;
  }
  img {
    position: absolute;
    right: 22px;
    top: 38px;
    width: 18px;
    height: 16px;
  }
}
.page {
  display: flex;
  justify-content: center;
  height: 80px;
  img {
    height: 30px;
    margin: 0 5px;
  }
  .page_last {
    margin-right: 30px;
  }
  .page_next {
    margin-left: 30px;
  }
  span {
    width: 8px;
    height: 11px;
    font-size: 14px;
    font-family: Arial;
    font-weight: bold;
    color: #c4c4c4;
    line-height: 30px;
    margin: 0 8px;
    cursor: pointer;
  }
}
</style>